<template>
  <section class="contact container">
    <h1 class="padding__sm text__center">Update Account</h1>
    <section class="form__grid margin__md">
      <p>
        If you have already created an account but are unable to log in, it is likely that your work email has changed in some way.
        Please input your old email and new email in the form to have us correct the issue for you.
        If your email has
        <i>not</i> changed please fill out the form with your current email as both old and new and be sure to explain the issue in the comments so that we can assist you.
      </p>
      <section class="contact-form">
        <label :class="[errors.oldEmail ? 'form--error' : null]">
          Old Email*
          <input type="email" v-model="form.oldEmail" placeholder="person@fbhl.com" />
        </label>
        <form-error :message="errors.oldEmail" />
        <label :class="[errors.newEmail ? 'form--error' : null]">
          New Email*
          <input type="email" v-model="form.newEmail" placeholder="person@fbhl.com" />
        </label>
        <form-error :message="errors.oldEmail" />
        <label :class="[errors.message ? 'form--error' : null]">
          Your Message*
          <textarea
            v-model="form.message"
            rows="5"
            id="message"
            placeholder="your message here"
          />
        </label>
        <form-error :message="errors.message" />
        <p
          class="text__sm text__center"
        >Your message will be submitted to a ticketing queue for a WOW representative to view. Please be patient in waiting for a response.</p>
        <button class="btn btn__green text__md" @click="submit">Send Message</button>
      </section>
    </section>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  metaInfo: {
    title: "Update Account | WOW"
  },
  data() {
    return {
      isValidated: true,
      form: {
        oldEmail: "",
        newEmail: "",
        subject: "Account Issue",
        message: ""
      },
      errors: {
        oldEmail: null,
        newEmail: null,
        message: null
      }
    };
  },
  computed: {
    ...mapGetters(["getUser"])
  },
  methods: {
    ...mapActions(["startSetIsSubmitting"]),
    emailTest(email) {
      const re = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      const reFBHL = /@fbhl.com/;
      return re.test(email) && reFBHL.test(email);
    },
    async submit() {
      this.validate();
      if (this.isValidated) {
        // import email of signed in user as senderEmail
        this.startSetIsSubmitting({ bool: true, heading: "Sending" });
        // try {
        //   // const res = await tickets.createTicket(this.form);
        //   console.log("success: ", res);
        // } catch (err) {
        //   console.log("failure:", err);
        // }
        setTimeout(() => {
          this.startSetIsSubmitting({ bool: false });
        }, 2000);
      }
    },
    validate() {
      this.isValidated = true;
      this.errors.oldEmail = !this.emailTest(this.form.oldEmail)
        ? "Please enter a valid fbhl address"
        : null;
      this.errors.newEmail = !this.emailTest(this.form.newEmail)
        ? "Please enter a valid fbhl address"
        : null;
      this.errors.message =
        this.form.message === "" ? "Please enter a message" : null;
      for (let key in this.errors) {
        if (this.errors[key] !== null) {
          this.isValidated = this.isValidated && false;
        } else {
          this.isValidated = this.isValidated && true;
        }
      }
    }
  },
  mounted() {
    if (this.getUser.email) {
      this.form.newEmail = this.getUser.email;
    }
  }
};
</script>

<style scoped lang="scss">
</style>